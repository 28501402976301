'use client';
import React, { useRef } from 'react';
import Image from 'next/image';
import heroIcons from './favicons.png';
import heroImage from './macbook.png';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
import { noop, SM_BREAKPOINT } from '@/lib/constants';

const ImageBox = () => {
  const containerRef = useRef<HTMLDivElement>(null);

  useGSAP(
    () => {
      const mm = gsap.matchMedia();
      let onMouseMove: (e: MouseEvent) => void;

      // Desktop
      mm.add(`(min-width: ${SM_BREAKPOINT + 1}px)`, () => {
        const xTo = gsap.quickTo('.anim-item', 'rotationX', { duration: 1, ease: 'power2' });
        const yTo = gsap.quickTo('.anim-item', 'rotationY', { duration: 1, ease: 'power2' });
        onMouseMove = (e: MouseEvent) => {
          const positionX = e.clientX / window.innerWidth + 0.5;
          const positionY = e.clientY / window.innerHeight + 0.15;

          yTo(positionX * 15);
          xTo(-positionY * 25);
        };
        window.addEventListener('mousemove', onMouseMove);
        return () => {
          window.removeEventListener('mousemove', onMouseMove);
        };
      });

      // Mobile
      mm.add(`(max-width: ${SM_BREAKPOINT}px)`, () => {
        onMouseMove = noop;
        window.addEventListener('mousemove', onMouseMove);
        return () => {
          window.removeEventListener('mousemove', onMouseMove);
        };
      });
    },
    { scope: containerRef },
  );

  return (
    <div ref={containerRef} className="relative max-w-[47.875rem] flex-1">
      <Image src={heroImage} alt="" priority />
      <Image
        className="anim-item absolute -right-[10%] top-[10%] z-10 [perspective:5000px] [transform-style:preserve-3d]"
        src={heroIcons}
        alt=""
        priority
      />
    </div>
  );
};

export default ImageBox;
