import React, { useRef, useEffect } from 'react';

type VideoPlayerProps = {
  videoSrc1: string;
  videoSrc2: string;
};

const VideoPlayer = ({ videoSrc1, videoSrc2 }: VideoPlayerProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    videoRef.current?.load();
  }, [videoSrc1, videoSrc2]);

  return (
    <video
      ref={videoRef}
      autoPlay
      muted
      loop
      playsInline
      className="z-4 absolute h-full w-full rounded-3xl object-cover p-4"
    >
      <source src={videoSrc1} type="video/webm" />
      <source src={videoSrc2} type="video/mp4" />
    </video>
  );
};

export default VideoPlayer;
