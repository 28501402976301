'use client';

import React, { useState } from 'react';
import { ChartBarSquareIcon, CubeTransparentIcon } from '@heroicons/react/24/outline';
import { ArrowPathRoundedSquareIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import VideoPlayer from './VideoPlayer';

type TabsContent = {
  title: string;
  subtitle: string;
  Icon: React.ElementType;
  videoSrc1: string;
  videoSrc2: string;
  content: string;
};

const tabsContent: TabsContent[] = [
  {
    title: 'Accelerate',
    subtitle: 'your builds & tests',
    Icon: CubeTransparentIcon,
    videoSrc1: '/assets/videos/home/accelerate.webm',
    videoSrc2: '/assets/videos/home/accelerate.mp4',
    content:
      'Build faster so you can ship sooner. Speed up your build and test cycles with Build Cache, Test Distribution, and Predictive Test Selection.',
  },
  {
    title: 'Troubleshoot',
    subtitle: 'failures faster',
    Icon: ArrowPathRoundedSquareIcon,
    videoSrc1: '/assets/videos/home/troubleshoot.webm',
    videoSrc2: '/assets/videos/home/troubleshoot.mp4',
    content:
      'Spend less time dealing with build failures. Rapidly identify root cause and improve reliability with Build Scan®, Flaky Test Detection, and Failure Analytics.',
  },
  {
    title: 'Observe',
    subtitle: 'build & test health',
    Icon: ChartBarSquareIcon,
    videoSrc1: '/assets/videos/home/observe.webm',
    videoSrc2: '/assets/videos/home/observe.mp4',
    content:
      'Get proactive about the health of your toolchain. Track build metrics across all of your projects with Reporting and Visualization, Performance Insights, and Trends.',
  },
];

const PillarsTabs = () => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  return (
    <div className="flex w-full flex-col gap-12">
      <div className="flex w-full justify-around gap-6">
        {tabsContent.map((item, idx) => (
          <button
            key={`tab_${idx}`}
            onClick={() => setActiveTabIndex(idx)}
            className={clsx(
              activeTabIndex === idx ? 'bg-dark-background' : 'bg-gray-10',
              'group flex w-full items-center justify-center gap-2 rounded-lg p-4 transition-colors hover:bg-dark-background md:justify-start',
            )}
          >
            <item.Icon
              className={clsx(
                activeTabIndex === idx ? 'text-gradle-blue' : 'text-gray-3',
                'size-6 transition-colors group-hover:text-gradle-blue md:mb-6',
              )}
            />
            <div className="hidden text-start md:block">
              <p className="text-h6 text-gray-5">{item.title}</p>
              <p className="text-body text-gray-4">{item.subtitle}</p>
            </div>
          </button>
        ))}
      </div>
      <div className="flex flex-col gap-4">
        <p className="text-h6 md:hidden">
          {tabsContent[activeTabIndex].title + ' ' + tabsContent[activeTabIndex].subtitle}
        </p>

        <div className="relative h-[26rem] w-full rounded-3xl border border-gray-2 lg:h-[29rem]">
          <VideoPlayer
            videoSrc1={tabsContent[activeTabIndex].videoSrc1}
            videoSrc2={tabsContent[activeTabIndex].videoSrc2}
          />
        </div>
      </div>
      <p className="text-h6 text-eyebrow max-w-[40rem] self-center text-gray-4">
        {tabsContent[activeTabIndex].content}
      </p>
    </div>
  );
};

export default PillarsTabs;
