'use client';

import React from 'react';

export interface ClientButtonInterface extends React.ComponentProps<'button'> {
  children?: React.ReactNode;
}

export const ClientButton = ({ children, ...props }: ClientButtonInterface) => {
  return <button {...props}>{children}</button>;
};
